import { inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { urlConcat } from "@logex/framework/utilities";
import { IAppSession, LG_APP_SESSION } from "../application";

export const LG_AUTHORIZATION_SERVICE = new InjectionToken<ILgAuthorizationService>(
    "LG_AUTHORIZATION_SERVICE"
);

// ----------------------------------------------------------------------------------
export interface AuthorizationServiceConfigV2 {
    product: string;
    instance: string;
    url: string;
}

export interface UserProfile {
    title: string;
    id: string;
    displayName: string;
    organizationId: number;
    name: string;
    email: string;
    login: string;
    note: string;
    isDisabled: boolean;
    impersonator: string | null;
}

// ----------------------------------------------------------------------------------
export interface ILgAuthorizationService {
    getUserProfile(): Observable<UserProfile>;
    checkProductInstanceAccess(): Observable<boolean>;
    getUserPermissions(): Observable<string[]>;
    getUserResources(
        resourceType: string,
        operation: "read" | "modify"
    ): Observable<string[] | null>;
}

// ----------------------------------------------------------------------------------
@Injectable()
export class LgAuthorizationServiceV2 implements ILgAuthorizationService {
    private _http = inject(HttpClient);

    private _product!: string;
    private _instance!: string;
    private _url!: string;

    private _session = inject<IAppSession>(LG_APP_SESSION);

    // ----------------------------------------------------------------------------------
    initialize(cfg: AuthorizationServiceConfigV2): void {
        this._product = cfg.product;
        this._instance = cfg.instance;
        this._url = urlConcat(cfg.url, "/api/v4");
    }

    getUserProfile(): Observable<UserProfile> {
        return this._http.get<UserProfile>(urlConcat(this._url, "/user/profile"));
    }

    checkProductInstanceAccess(): Observable<boolean> {
        return this._http.get<boolean>(urlConcat(this._url, "/access/instance"), {
            params: {
                product: this._product,
                applicationInstance: this._instance
            }
        });
    }

    getUserPermissions(): Observable<string[]> {
        const clientId = this._session.clientId;
        return this._http.get<string[]>(urlConcat(this._url, "/user/flat-permissions"), {
            params: {
                organizationIdentifier: clientId,
                product: this._product,
                applicationInstance: this._instance
            }
        });
    }

    getUserResources(resourceType: string, operation: "read" | "modify"): Observable<string[]> {
        return this._http
            .get<{ resources: string[] }>(urlConcat(this._url, "/user/resources"), {
                params: {
                    productInstance: this._instance,
                    resourceType,
                    operation
                }
            })
            .pipe(map(x => x.resources));
    }
}
