import {
    IUser,
    UserIdentity
} from "@logex/framework/lg-application";

export interface IAppUser extends IUser {
    setUserData(userData: UserIdentity | null): void;
}

export class AppUser implements IAppUser {
    id!: number;
    name!: string;
    roles: Record<string, boolean> = {};
    title!: string;
    userid!: string;
    ziekenhuiscode!: number;
    ziekenhuisnaam!: string;

    public hasPermission(role: string): boolean {
        return this.roles[role] ?? false;
    }

    setUserData(userData: UserIdentity | null) {
        console.log('updated user data from oauth service: ', userData);
    }
}
