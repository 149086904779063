export enum NavigationIds {
    EditorStart = "editorStart",
    AllSubContracts = "allSubContracts",
    DataSets = "dataSets",
    EntityListGroups = "entityListGroups",
    EntityLists = "entityLists",
    EntityListDraftCreate = "entityListDraftCreate",
    EntityListDraftEdit = "entityListDraftEdit",
    DataSetDraftCreate = "dataSetDraftCreate",
    DataSetDraftEdit = "dataSetDraftEdit",
    EntityListGroupDraftCreate = "entityListGroupDraftCreate",
    EntityListGroupDraftEdit = "entityListGroupDraftEdit",
}
