import ldIsString from "lodash-es/isString";
import ldIsArray from "lodash-es/isArray";
import ldMap from "lodash-es/map";
import {
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    ViewChild,
    Renderer2,
    NgZone,
    OnDestroy,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { trigger, state, style, transition, animate, AnimationEvent } from "@angular/animations";
import { ConnectedOverlayPositionChange, ViewportRuler } from "@angular/cdk/overlay";

import { Subject, Observable } from "rxjs";
import { first, takeUntil } from "rxjs/operators";

import { ISimpleDropdownDefinition, ISimpleDropdownTextCallback } from "./lg-simple-dropdown.types";
import { LgScrollerService, ScrollerApi1d } from "../../scrolling/lg-scroller.service";
import { LgTooltipService } from "../../lg-tooltip/lg-tooltip.service";
import { elementHasClass } from "@logex/framework/utilities";
import { NgClass, NgForOf } from "@angular/common";
import { A11yModule } from "@angular/cdk/a11y";

export interface IResult {
    selected: boolean;
    id?: number | string;
}

interface IEntry {
    id: number | string;
    name: string;
}

@Component({
    standalone: true,
    selector: "lg-simple-dropdown-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="main" cdkTrapFocus>
            <div class="scrollable" #contentHolder (click)="_onMouseClick($event)">
                <div
                    class="entry"
                    *ngFor="let entry of _converted; index as index"
                    [ngClass]="{ selected: entry.id === _cursorId }"
                    [attr.value]="index"
                >
                    {{ entry.name }}
                </div>
            </div>
        </div>
    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [NgForOf, NgClass, A11yModule],

    animations: [
        trigger("state", [
            state("initial, hidden", style({ opacity: 0 })),
            state("onTop, onBottom", style({ opacity: 1 })),

            transition("* => onBottom", [
                style({ opacity: 0, transform: "translateY(-4px)" }),
                animate(250, style({ opacity: 1, transform: "translateY(0)" }))
            ]),

            transition("* => onTop", [
                style({ opacity: 0, transform: "translateY(4px)" }),
                animate(250, style({ opacity: 1, transform: "translateY(0)" }))
            ]),

            transition("* => hidden", [animate(150, style({ opacity: 0 }))])
        ])
    ]
})
export class LgSimpleDropdownPopupComponent implements OnDestroy {
    private _changeDetector = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _scrollerService = inject(LgScrollerService);
    private _tooltipService: LgTooltipService = inject(LgTooltipService);
    private _viewportRuler = inject(ViewportRuler);
    @ViewChild("contentHolder", { static: true }) public _contentHolder: ElementRef | undefined;

    public set definition(value: ISimpleDropdownDefinition) {
        this._definition = value;
        this._convert();
    }

    public get definition(): ISimpleDropdownDefinition | undefined {
        return this._definition;
    }

    public set entryText(value: ISimpleDropdownTextCallback | undefined) {
        this._entryTextFn = value;
        this._convert();
    }

    public get entryText(): ISimpleDropdownTextCallback | undefined {
        return this._entryTextFn;
    }

    public _isTop = true;

    public _shownAbove = false;

    private _matchWidth = false;

    private _popupClassName = "lg-simple-dropdown-popup";

    private _definition: ISimpleDropdownDefinition | undefined;

    private _entryTextFn?: ISimpleDropdownTextCallback | undefined;

    private _currentEntryIndex = 0;

    public _cursorId: any;

    public _converted: IEntry[] = [];

    private _scroller?: ScrollerApi1d | undefined = undefined;

    private readonly _destroyed$ = new Subject<void>();

    private readonly _result$ = new Subject<IResult>();

    private readonly _onHide$: Subject<void> = new Subject();
    @HostBinding("class") public _className!: string;

    @HostBinding("style.width.px") public _width: number | undefined;

    @HostBinding("@state")
    public _visibility: "hidden" | "initial" | "onTop" | "onBottom" = "initial";

    @HostListener("@state.done", ["$event"])
    public _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._onHide$.next();
        }
    }

    // ----------------------------------------------------------------------------------
    public _initialize(
        _target: ElementRef,
        matchWidth: boolean,
        popupClassName: string,
        currentValue: number | string
    ): Observable<IResult> {
        this._popupClassName = popupClassName;
        this._matchWidth = matchWidth;

        if (this._matchWidth) {
            const width = _target.nativeElement.offsetWidth;
            this._width = width - 3;
        }

        this._updateClassName();

        this._cursorId = currentValue;
        this._scroller =
            this._contentHolder && this._scrollerService.createVertical(this._contentHolder);

        this._ngZone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
            const totalHeight = this._elementRef.nativeElement.offsetHeight;
            const viewport = this._viewportRuler.getViewportSize();
            if (totalHeight >= viewport.height * 0.49 && this._contentHolder) {
                // half of screen, and a bit extra
                const padding = totalHeight - this._contentHolder.nativeElement.clientHeight;
                const maxHeight = Math.floor(viewport.height * 0.49 - padding);
                this._renderer.setStyle(
                    this._contentHolder.nativeElement,
                    "maxHeight",
                    maxHeight + "px"
                );
            }
            this._updateScroller();
        });

        return this._result$.asObservable();
    }

    // ----------------------------------------------------------------------------------
    public _updatePosition(position: ConnectedOverlayPositionChange): void {
        this._shownAbove = position.connectionPair.overlayY === "bottom";

        this._updateClassName();

        if (this._visibility === "initial") {
            this._visibility = this._shownAbove ? "onTop" : "onBottom";
        }

        this._ngZone.run(() => {
            // empty
        });
    }

    // ----------------------------------------------------------------------------------
    public hide(): Observable<void> {
        this._visibility = "hidden";
        return this._onHide$.asObservable();
    }

    // ----------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        this._scroller?.destroy();
        this._scroller = undefined;
        this._destroyed$.next();
        this._destroyed$.complete();
        this._result$.complete();
    }

    // ----------------------------------------------------------------------------------
    private _updateClassName(): void {
        this._className = this._popupClassName || "lg-simple-dropdown-popup";

        if (this._matchWidth) {
            this._className += " match-width";
        }

        this._className += this._shownAbove ? " top" : " bottom";
        this._changeDetector.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _convert(): void {
        const definition = this._definition;
        const fn = this._entryTextFn;

        if (!definition) {
            this._converted = [];
            return;
        }

        if (ldIsArray(definition)) {
            if (ldIsString(definition[0])) {
                if (fn) {
                    this._converted = (definition as string[]).map(e => ({
                        id: e,
                        name: fn({ id: e, name: e })
                    }));
                } else {
                    this._converted = (definition as string[]).map(e => ({ id: e, name: e }));
                }
            } else if (fn) {
                this._converted = (definition as Array<{ id: number | string; name: string }>).map(
                    e => ({
                        id: e.id,
                        name: fn(e)
                    })
                );
            } else {
                this._converted = definition as IEntry[];
            }
        } else {
            this._converted = ldMap(
                definition as Record<string, string>,
                (v: string, k: string) => {
                    if (fn) return { id: k, name: fn({ id: k, name: v }) };
                    return { id: k, name: v };
                }
            );
        }

        this._currentEntryIndex = 0;
        const currentEntry = this._converted.find((el, index) => {
            if (el.id === this._cursorId) {
                this._currentEntryIndex = index;
                return true;
            }
            return false;
        });

        this._cursorId = currentEntry ? currentEntry.id : this._converted[0].id;
    }

    // ---------------------------------------------------------------------------------------------
    //  Update the scroller size and position
    // ---------------------------------------------------------------------------------------------
    private _updateScroller(): void {
        this._ngZone.onMicrotaskEmpty.pipe(takeUntil(this._destroyed$), first()).subscribe(() => {
            if (this._scroller) {
                this._scroller.resize();
                const selectedElement =
                    this._contentHolder &&
                    this._contentHolder.nativeElement.querySelector(".selected");
                if (selectedElement) {
                    this._scroller.ensureVisible(new ElementRef(selectedElement));
                }
            }
        });
    }

    // ---------------------------------------------------------------------------------------------
    //  Close and selection
    // ---------------------------------------------------------------------------------------------
    public _attemptClose(): void {
        this._result$.next({
            selected: false
        });
    }

    private _doSelect(): void {
        this._result$.next({
            selected: true,
            id: this._cursorId
        });
    }

    // ---------------------------------------------------------------------------------------------
    //  Keyboard handler
    // ---------------------------------------------------------------------------------------------
    @HostListener("document:keydown", ["$event.keyCode"])
    public _keyClick(keyCode: number): boolean {
        if (!this._isTop) {
            return true; // no handling!
        }

        if (keyCode === 27) {
            this._attemptClose();
            return false;
        }

        let changed = false;
        if (this._converted.length) {
            if (keyCode === 38) {
                if (this._currentEntryIndex > 0) {
                    --this._currentEntryIndex;
                    this._cursorId = this._converted[this._currentEntryIndex].id;
                    changed = true;
                } else {
                    return false;
                }
            } else if (keyCode === 40) {
                if (this._currentEntryIndex < this._converted.length - 1) {
                    ++this._currentEntryIndex;
                    this._cursorId = this._converted[this._currentEntryIndex].id;
                    changed = true;
                } else {
                    return false;
                }
            } else if (keyCode === 36) {
                this._currentEntryIndex = 0;
                this._cursorId = this._converted[this._currentEntryIndex].id;
                changed = true;
            } else if (keyCode === 35) {
                this._currentEntryIndex = this._converted.length - 1;
                this._cursorId = this._converted[this._currentEntryIndex].id;
                changed = true;
            } else if (keyCode === 13) {
                this._doSelect();
                return false;
            }
        }

        if (changed) {
            this._updateScroller();
            return false;
        }
        return true;
    }

    // ---------------------------------------------------------------------------------------------
    //  Generic click handler for the content
    // ---------------------------------------------------------------------------------------------
    public _onMouseClick(event: MouseEvent): boolean {
        let target = event.target as HTMLElement;

        if (target.parentElement && elementHasClass(target.parentElement, "entry")) {
            target = target.parentElement;
        }

        if (elementHasClass(target, "entry")) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            this._currentEntryIndex = +target.attributes["value" as any].value;
            this._cursorId = this._converted[this._currentEntryIndex].id;

            this._doSelect();

            return false;
        }
        return true;
    }
}

/*


                    // --------------------------------------------------------------------------------------------------------
                    function doShow() {
                        oldValue = scope.current;
                        let offs = element.offset();
                        const width = element.outerWidth();
                        const height = element.outerHeight();
                        scope.cursor = scope.current;
                        convert();
                        holder = $( `<div class='${scope.popupClassName || "lg-simple-dropdown-popup"}'></div>` );

                        if ( utility.toBoolean( scope.matchWidth ) ) {
                            holder.css( { top: offs.top + height, left: offs.left, width: width - 3 } );
                        } else {
                            holder.css( { top: offs.top + height, left: offs.left + width - 302 } );
                        }
                        popupScope = scope.$new();
                        $compile( holder.html( popupTemplate ) )( popupScope );

                        if ( scope.$root.$$phase ) {
                            scope.$evalAsync( finish );
                        } else {
                            scope.$apply();
                            finish();
                        }

                        function finish() {
                            isTop = true;
                            overlayApi = overlay.show( { onClick: attemptClose, trapFocusTo: holder, onDeactivate: () => isTop = false, onActivate: () => isTop = true } );
                            holder.appendTo( document.body );

                            let currentHeight = holder.outerHeight();
                            const currentWidth = holder.outerWidth();
                            const currentTop = holder.offset().top;
                            const wHeight = $( window ).height();
                            const wOffs = $( document ).scrollTop();
                            if ( $animate.enabled() ) {
                                $animate.addClass( holder, "visible" );
                            } else {
                                holder.addClass( "visible" );
                            }
                            if ( scope.popupPosition == "right" ) {
                                holder.css( { left: offs.left } );
                            } else if ( !utility.toBoolean( scope.matchWidth ) ) {
                                holder.css( { left: offs.left + width - currentWidth } );
                            }
                            holder.addClass( "bottom" );
                            if ( currentTop + currentHeight - wOffs > wHeight ) {
                                var scroll = holder.find( ".scrollable" );
                                var padding = currentHeight - scroll.height();
                                if ( currentTop > wOffs + wHeight * 0.55 ) {
                                    offs = element.offset();
                                    holder.css( { top: "auto", bottom: $( document.body ).height() - offs.top } );
                                    holder.removeClass( "bottom" ).addClass( "top" );
                                    if ( ( offs.top - currentHeight ) < wOffs ) {
                                        scroll.css( "maxHeight", offs.top - wOffs - padding );
                                    }
                                } else {
                                    currentHeight = wHeight - ( currentTop - wOffs );
                                    scroll.css( "maxHeight", currentHeight - padding );
                                }
                            }

                            const current = holder.find( ".selected" );
                            $animate.addClass( templateRoot, "active" );
                            holder.on( "click", ".entry", entryClick );
                            $document.bind( "keydown", keyClick );
                            setTimeout( function () {
                                if ( !popupScope ) return;
                                scroller = logexScroller.create( holder.find( ".scrollable" ) );
                                scroller.ensureVisible( current );
                            }, 0 );
                        }
                    }

            }
        }
    ]
    );
*/
