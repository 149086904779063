import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { LG_AUTHENTICATION_SERVICE } from "@logex/framework/lg-application";

@Component({
    selector: "login",
    template: `<lg-button text="Login" (click)="_login()"></lg-button>"`
})
export class LoginComponent {
    private _authService = inject(LG_AUTHENTICATION_SERVICE);
    private _router = inject(Router);

    protected _login(): void {
        this._authService.login().then(() => {
            this._router.navigateByUrl("/");
        });
    }
}
