import {
    Component,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    isDevMode,
    OnInit,
    OnDestroy,
    ElementRef,
    Renderer2,
    ChangeDetectorRef,
    inject
} from "@angular/core";
import { Subject, Subscription, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { toInteger } from "@logex/framework/utilities";
import {
    LgObserveSizeService,
    LgObserveSizeApi,
    LgObserveSizeOptions,
    SizeEvent
} from "./lg-observe-size.service";

@Component({
    standalone: true,
    selector: "lg-rectangle",
    template: ` <ng-content></ng-content> `,
    host: {
        class: "lg-rectangle"
    },
    encapsulation: ViewEncapsulation.None
})
export class LgRectangleComponent implements OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);

    /**
     * Specifies amount of time to be ignored by observer between size changes.
     *
     * @default 100
     */
    @Input("auditTime") set options(value: number | string) {
        this._auditTime = toInteger(value);
        if (this._initialized && isDevMode()) {
            console.warn("lgRectangle: cannot change auditTime after creation");
        }
    }

    get options(): number {
        return this._auditTime;
    }

    /**
     * Emits events when rectangle size is changed.
     */
    @Output() readonly sizeChange = new EventEmitter<SizeEvent>();

    change(options?: Partial<LgObserveSizeOptions>): Observable<SizeEvent> {
        if (!options) {
            options = { ...LgObserveSizeService._defaults, auditTime: this._auditTime };
        } else {
            options = { ...LgObserveSizeService._defaults, auditTime: this._auditTime, ...options };
        }

        return this._api.change(options).pipe(takeUntil(this._destroyed$));
    }

    width = 0;
    height = 0;

    private _initialized = false;
    private _auditTime = 100;
    private readonly _destroyed$ = new Subject<void>();
    private _api: LgObserveSizeApi;
    private _eventSubscription!: Subscription;

    constructor() {
        const _elementRef = inject(ElementRef);
        const _renderer = inject(Renderer2);
        const _observeService = inject(LgObserveSizeService);

        this._api = _observeService.observe(_elementRef, _renderer);
    }

    getCurrentSize(): SizeEvent {
        const latest = this._api.getCurrentSize();
        this.width = latest.width;
        this.height = latest.height;
        return latest;
    }

    ngOnInit(): void {
        this._eventSubscription = this.change({
            auditTime: this._auditTime,
            type: "all",
            outsideZone: false
        }).subscribe(ev => {
            this.width = ev.width;
            this.height = ev.height;
            this._changeDetectorRef.markForCheck();
            Promise.resolve().then(() => {
                this.sizeChange.next(ev);
            });
        });

        this._initialized = true;
    }

    ngOnDestroy(): void {
        if (this._eventSubscription) this._eventSubscription.unsubscribe();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
