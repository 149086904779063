import {inject, Injectable} from "@angular/core";
import {ITokenAuthenticationService, OidcAuthService, UserIdentity} from "@logex/framework/lg-application";
import {AppStaticConfiguration} from "@shared/services/app-startup.types";

// Totally fake authentication service. For real application, please use implementation of the service from
// framework  (based on OIDC lib) or Data-Entry or Codman (based on auth0 lib)
@Injectable()
export class AppAuthenticationService implements ITokenAuthenticationService {
    private _oidcAuthenticationService: OidcAuthService = inject(OidcAuthService);

    constructor() {
        this.logout();
    }

    getAccessToken(): Promise<string> {
        return this._oidcAuthenticationService.getAccessToken();
    }

    configure(config: AppStaticConfiguration, basePath: string): void {
        this._oidcAuthenticationService.configure({
            authority: config.services.authentication.authority,
            type: config.services.authentication.type ?? "generic",
            clientId: config.services.authentication.clientId,
            audience: config.services.authentication.audience,
            basePath,
            redirectUrl: window.location.href,
            scope: "openid profile email",
        });
    }

    async login(): Promise<boolean> {
        const isLoggedIn = await this._oidcAuthenticationService.login();
        this.loggedIn = isLoggedIn;
        this.user = { ...this._oidcAuthenticationService.user };
        return isLoggedIn;
    }

    isLoggedIn(): Promise<boolean> {
        return Promise.resolve(this.loggedIn ?? false);
    }

    logout(): Promise<void> {
        this.loggedIn = false;
        this.user = undefined;
        return Promise.resolve();
    }

    loggedIn: boolean | undefined;
    user: UserIdentity | undefined;
}
