import {
    OnInit,
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    OnDestroy,
    TemplateRef,
    inject
} from "@angular/core";
import { Subscription } from "rxjs";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgSimpleChanges } from "@logex/framework/types";

import { LgFiltersPanelService } from "./services/lg-filters-panel.service";
import { LgSlideoutApi, LgSlideoutService, LgSlideoutVariant } from "../lg-slideout";
import { LgBookmarksStateService } from "./services/lg-bookmarks-state.service";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-filterset-row",
    templateUrl: "./lg-filterset-row.component.html",
    host: {
        class: "lg-filterset-row"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default
})
export class LgFiltersetRowComponent implements OnChanges, OnDestroy, OnInit {
    private _bookmarksState = inject(LgBookmarksStateService);
    private _filtersPanelService = inject(LgFiltersPanelService);
    private _slideoutService = inject(LgSlideoutService);

    @Input({ required: true }) slideoutPanelId!: string;

    @Input("variant") _variant: LgSlideoutVariant = "right";

    @Input() set disabled(val: boolean | "true" | "false") {
        this._disabled = toBoolean(val);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input() initializeAsPinned = false;

    @Input() initializeAsExpanded = false;

    @Input() set hidePin(value: boolean | "true" | "false") {
        this._hidePin = toBoolean(value);
    }

    get hidePin(): boolean {
        return this._hidePin;
    }

    /**
     * @Required
     * All filters definitions
     */
    @Input({ required: true }) definition!: LgFilterSet;

    @Input() set stateKey(val: string) {
        this._filtersPanelService.setKey(val);
        this._stateKey = val;
    }

    get stateKey(): string {
        return this._stateKey;
    }

    @Input() set customFooterTemplate(ref: TemplateRef<void>) {
        this._customFooterTemplate = ref;
        this._filtersPanelService?.setCustomFooterTemplate(ref);
    }

    get customFooterTemplate(): TemplateRef<void> | undefined {
        return this._customFooterTemplate;
    }

    private _changeSubscription: Subscription | null = null;

    private _stateKey = "";
    private _customFooterTemplate?: TemplateRef<void>;
    private slideoutApi!: LgSlideoutApi;

    private _disabled = false;
    private _hidePin = true;

    async ngOnInit(): Promise<void> {
        this.slideoutApi = this._slideoutService.api(this.slideoutPanelId, this._variant);
        this._filtersPanelService.setApi(this.slideoutApi);
    }

    ngOnChanges(changes: LgSimpleChanges<LgFiltersetRowComponent>): void {
        if (changes.definition) {
            this._unsubscribeChanges();
            if (this.definition) {
                this._filtersPanelService.setSet(this.definition);
                this._changeSubscription = this.definition.onChanged.subscribe(() =>
                    this._onFiltersChanged()
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeChanges();
    }

    private _unsubscribeChanges(): void {
        if (this._changeSubscription) {
            this._changeSubscription.unsubscribe();
            this._changeSubscription = null;
        }
    }

    private _onFiltersChanged(): void {
        if (!this.definition.isAnyActive()) {
            this._bookmarksState.selectNewBookmark(this._stateKey);
        }

        this._bookmarksState.notifyFiltersWereChanged(this._stateKey, this.definition.serialize());
    }
}
