import ldFindLast from "lodash-es/findLast";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    inject,
    Input,
    isDevMode,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { takeUntil } from "rxjs/operators";

import {
    IQuickSettingsMenuItem,
    IQuickSettingsMenuRegularItem,
    IQuickSettingsSubmenu,
    LgQuickSettingsMenuHost,
    LgQuickSettingsMenuPopupComponent,
    QuickSettingsMenuType
} from "@logex/framework/ui-core";
import { LgNavigationService, ProcessedNavNode } from "@logex/framework/lg-application";
import { toBoolean } from "@logex/framework/utilities";

interface IDefinition {
    definition: IQuickSettingsMenuItem[];
    selection: IQuickSettingsMenuRegularItem[];
}

@Component({
    selector: "lg-breadcrumb",
    templateUrl: "./lg-breadcrumb.component.html",
    host: {
        class: "lg-breadcrumb"
    }
})
export class LgBreadcrumbComponent
    extends LgQuickSettingsMenuHost
    implements OnInit, OnChanges, OnDestroy
{
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _navigationService = inject(LgNavigationService);
    private _titleService = inject(Title);

    /**
     * Navigation id of the home page.
     */
    @Input() homeId: string | null = null;

    @Input() toolNameLc = "APP.ToolName";

    @Input() titlePrefixLc: string | null = null;

    @Input() toolNameParams: Record<string, any> | null = {};

    @Input() set noLastHighlight(value: boolean | "true" | "false") {
        this._noLastHighlight = toBoolean(value);
    }

    /* Setting for breadcrumb children to be narrowed on configured level */
    @Input() narrowedChildren: Record<number, boolean> | null = {};

    get noLastHighlight(): boolean {
        return this._noLastHighlight;
    }

    @Input() set highlightRespectsInvisibleChildren(value: boolean | "true" | "false") {
        this._highlightRespectsInvisibleChildren = toBoolean(value);
    }

    get highlightRespectsInvisibleChildren(): boolean {
        return this._highlightRespectsInvisibleChildren;
    }

    @Input() set noPageTitle(value: boolean | "true" | "false") {
        this._noPageTitle = toBoolean(value);
    }

    get noPageTitle(): boolean {
        return this._noPageTitle;
    }

    @HostBinding("class.lg-breadcrumb--no-highlight")
    get _noHighlight(): boolean {
        return (
            this._noLastHighlight ||
            (this._highlightRespectsInvisibleChildren && this._lastChildHidden)
        );
    }

    _state: ProcessedNavNode[] = [];
    _activeIndex: number | null = null;
    _lastChildHidden = false;

    private _noLastHighlight = false;
    private _highlightRespectsInvisibleChildren = false;
    private _noPageTitle = false;

    // --------------------------------------------------------------------------------------------------------
    public constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    // --------------------------------------------------------------------------------------------------------
    async _navigateHome(_event: MouseEvent): Promise<void> {
        if (!this.homeId || this._state.length === 0) return;

        await this._navigationService.ready();
        const node = this._navigationService.getNodeByIdSync(this.homeId);
        if (!node) {
            if (isDevMode()) {
                console.warn(`lg-breadcrumb: unknown home node ${this.homeId}`);
            }
            return;
        }

        this._navigationService.navigateTo(node);
    }

    // --------------------------------------------------------------------------------------------------------
    _showMenu(_event: MouseEvent, index: number, breadcrumbElement: HTMLElement): boolean {
        this._activeIndex = index;
        this._doShow(breadcrumbElement);
        return false;
    }

    // --------------------------------------------------------------------------------------------------------
    ngOnInit(): void {
        this._navigationService
            .currentNodePath$()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(path => {
                this._hideSettingsPopup();
                this._update(path);
            });
    }

    // --------------------------------------------------------------------------------------------------------
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.titlePrefixLc) {
            this._update(this._navigationService.getCurrentNodePathSync());
        }
    }

    // --------------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        super._onDestroy();
    }

    isNarrowedChildren(index: number): boolean {
        return this.narrowedChildren?.[index] ?? false;
    }

    // --------------------------------------------------------------------------------------------------------
    private _update(path: ProcessedNavNode[]): void {
        this._lastChildHidden = path[path.length - 1]?.noBreadcrumb ?? false;
        this._state = path.filter(node => !node.noBreadcrumb);

        if (!this._noPageTitle) {
            const prefix = !this.titlePrefixLc
                ? ""
                : this._translateService.translate(this.titlePrefixLc, this.toolNameParams);
            const titleSource = ldFindLast(
                path,
                p => (p.pageTitle || p.name) && !p.noPageTitle
            ) as { pageTitle: string; name: string };
            let title: string;
            if (!titleSource) {
                title = prefix;
            } else if (prefix) {
                title = prefix + ": " + (titleSource.pageTitle || titleSource.name);
            } else {
                title = titleSource.pageTitle || titleSource.name;
            }
            this._titleService.setTitle(title);
        }
    }

    // --------------------------------------------------------------------------------------------------------
    protected override _hideSettingsPopup(): void {
        if (this._activeIndex === null) return;

        this._activeIndex = null;
        super._hideSettingsPopup();

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }

    // --------------------------------------------------------------------------------------------------------
    private _doShow(element: HTMLElement): void {
        const alignment = "start";
        const elementRef = new ElementRef(element);

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: alignment, originY: "bottom", overlayX: alignment, overlayY: "top" },
                { originX: alignment, originY: "top", overlayX: alignment, overlayY: "bottom" }
            ]);

        const currentDefinition: IDefinition = this._createDefinition(
            this._activeIndex ?? 0,
            this._state[this._activeIndex ?? 0]
        );

        this._showSettingsPopup(elementRef, strategy, true, {
            definition: currentDefinition.definition,
            compact: false,
            selectedItems: currentDefinition.selection
        }).subscribe(null, null, () => this._hideSettingsPopup());

        this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _createDefinition(stateIndex: number, node: ProcessedNavNode): IDefinition {
        const selection: IQuickSettingsMenuRegularItem[] = [];

        const convert = (
            children: ProcessedNavNode[] | null,
            depth: number
        ): IQuickSettingsMenuItem[] => {
            if (!children || children.length === 0) return [];

            const result: IQuickSettingsMenuItem[] = [];
            for (const item of children) {
                if (item.noAccessRights || item.hidden || item.disabled) continue;
                let newItem: IQuickSettingsMenuItem | null = null;
                if (item.children && item.children.length) {
                    const entry: IQuickSettingsSubmenu = {
                        type: QuickSettingsMenuType.Submenu,
                        name: (item.namePrefix || "") + item.name,
                        children: convert(item.children, depth + 1)
                    };
                    if (entry.children.length > 0) {
                        newItem = entry;
                        if (
                            this._state.length > depth + stateIndex &&
                            this._state[depth + stateIndex] === item
                        )
                            selection[depth] = entry;
                    }
                }
                if (!newItem) {
                    const entry: IQuickSettingsMenuItem = {
                        type: QuickSettingsMenuType.Choice,
                        name: (item.namePrefix || "") + item.name,
                        onClick: () => this._navigationService.navigateTo(item)
                    };
                    newItem = entry;
                    if (
                        this._state.length > depth + stateIndex &&
                        this._state[depth + stateIndex] === item
                    )
                        selection[depth] = entry;
                }
                if (newItem) result.push(newItem);
            }
            return result;
        };

        const currentDefinition: IDefinition = {
            definition: convert(node.parent ? node.parent.children : [node], 0),
            selection
        };

        return currentDefinition;
    }
}
