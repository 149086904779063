import { ChangeDetectionStrategy, Component, Inject, isDevMode } from "@angular/core";

import { IUser, LG_USER_INFO } from "@logex/framework/lg-application";
import { SidebarHelpStandardLinks } from "@logex/framework/lg-layout";

import { ApplicationPermissions } from "@shared";

@Component({
    selector: "authorized-component",
    templateUrl: "./authorized.component.html",
    changeDetection: ChangeDetectionStrategy.Default
})
export class AuthorizedComponent {
    protected _devMode: boolean;
    protected _internal: boolean;
    protected _viewSettings: boolean;
    protected _today = new Date();
    protected _name!: string;
    protected _standardLinks: SidebarHelpStandardLinks = {
        FAQ: "https://logex.com"
    };

    constructor(@Inject(LG_USER_INFO) public userInfo: IUser) {
        this._internal = userInfo.hasPermission(ApplicationPermissions.Admin);
        this._viewSettings = userInfo.hasPermission(ApplicationPermissions.Admin);
        this._devMode = isDevMode();
        this._name = userInfo.name;
    }
}
