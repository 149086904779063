import { Injectable } from "@angular/core";
import { LgApplicationPermissionsBase } from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class ApplicationPermissions extends LgApplicationPermissionsBase {
    static readonly Admin = "admin";
    static readonly Edit = "edit";
    static readonly Read = "read";
    // Application specific roles
    // static readonly Admin: string = "demo_admin";
    static readonly Access: string = "demo_access";
    static readonly Open: string = "demo_open";
    static readonly ViewSettings: string = "demo_view_settings";
    static readonly EditSettings: string = "demo_edit_settings";

    static readonly SwitchHospital: string = "demo_switch_hospital";
    static readonly Internal: string = "internal";
    static readonly MergedHospitals: string = "demo_merged_hospital";
    static readonly SwitchLanguage: string = "demo_switch_language";

    static readonly DemoAccess: string = "demo_access";

    // Relevant only for LgBackend tools
    // static readonly MANAGE_SCENARIOS = "demo_manage_scenarios";
    // static readonly SWITCH_SCENARIO = "demo_switch_scenario";
    constructor() {
        super();
        (this as any).InternalRole = ApplicationPermissions.Internal;
    }
}
