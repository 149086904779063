import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface IException {
    source: string;
    methodName: string;
    args: any;
}

@Injectable({ providedIn: "root" })
export class ExceptionsService {
    readonly exception: Observable<IException | null>;

    private _exception: BehaviorSubject<IException | null>;

    constructor() {
        this._exception = new BehaviorSubject<IException | null>(null);
        this.exception = this._exception.asObservable();
    }

    add(exception: IException): void {
        // const newExceptions = this._exception.getValue().slice();
        // newExceptions.push( exception );

        this._exception.next(exception);
    }

    clearAll(): void {
        this._exception.next(null);
    }
}
