/* Ease functions ----------------------------------------------------------------------------------------------*/
// http://matthewlein.com/ceaser/
export const easingDefs = {
    // speed up towards end
    easeInCubic: "cubic-bezier(0.550, 0.055, 0.675, 0.190)",
    easeInQuart: "cubic-bezier(0.895, 0.030, 0.685, 0.220)",
    easeInQuint: "cubic-bezier(0.755, 0.050, 0.855, 0.060)",
    easeInBack: "cubic-bezier(0.600, -0.280, 0.735, 0.045)",
    easeInAdam: "cubic-bezier(0.550, 0.055, 0.570, 0.540)",

    // slow down towards end
    easeOutCubic: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
    easeOutQuart: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
    easeOutQuint: "cubic-bezier(0.230, 1.000, 0.320, 1.000)",
    easeOutBack: "cubic-bezier(0.175, 0.885, 0.320, 1.275)",
    easeOutAdam: "cubic-bezier( 0.455, 0.590, 0.355, 1.000 )",

    // speed up then slow down
    easeInOutCubic: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
    easeInOutQuart: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
    easeInOutQuint: "cubic-bezier(0.860, 0.000, 0.070, 1.000)",
    easeInOutBack: "cubic-bezier(0.680, -0.550, 0.265, 1.550)"
};
