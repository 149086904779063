import ldDebounce from "lodash-es/debounce";
import { Component, inject, ViewChild } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";
import { ComponentType } from "@angular/cdk/portal";
import { LgTristateSliderComponent, LG_TRISTATE_SLIDER_STATE } from "@logex/framework/ui-core";
import { BehaviorSubject, Observable } from "rxjs";

const DEFAULT_DELAY = 500;

export interface ITristateSliderFilterDefinition extends IFilterDefinition {
    filterType: "tristate-slider";

    /**
     * Label for the excluding (red-colored) state. Defaults to "Contains".
     */
    containsLabel?: string;
    /**
     * Label for the excluding (red-colored) short state visible in preview. Defaults to "Cont.".
     */
    containsLabelShort?: string;
    /**
     * Label for the excluding (red-colored) state. Defaults to "Excludes"
     */
    excludesLabel?: string;
    /**
     * Label for the excluding (red-colored) short state visible in preview. Defaults to "Excl."
     */
    excludesLabelShort?: string;
    /**
     * Specifies the delay im milliseconds for triggering change on filter definition.
     * This delay is used for delaying filterset reaction to filters' changed state.
     * Example:
     * Filter moves from unset -> contains -> excludes -> unset.
     * Without delay, refilter would trigger right away.
     * With delay, we can move from unset to excludes and trigger the change once.
     * Defaults to 500.
     */
    delay?: number;
    /**
     * Specifies the function to call when working with delay.
     * Takes in one boolean parameter.
     * Function is called twice.
     * First time, the function is called with boolean false when inner component state changes and filterset is waiting for delay.
     * Second time, the function is called with boolean true when filterset is actually changed.
     * Example usage:
     * delayCallback = (finished: boolean) => {
     *     if (!finished) this._loader.show();
     *     else this._loader.hide();
     * }
     */
    delayCallback?: (finished: boolean) => void;
}
/**
 *
 *
 */
export class TristateSliderFilterRenderer implements IFilterRenderer {
    sliderState!: LG_TRISTATE_SLIDER_STATE;
    sliderLabel = "";
    private _previewName$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    constructor(
        private _definition: ITristateSliderFilterDefinition,
        private _filters: any,
        private _lgTranslate: LgTranslateService
    ) {
        if (this._definition.delay == null) this._definition.delay = DEFAULT_DELAY;
    }

    createStorage(): void {
        if (this._definition.storage && this._filters[this._definition.storage] === undefined) {
            this._filters[this._definition.storage] = null;
        }
    }

    active(): boolean {
        return !!this._definition.storage && this._filters[this._definition.storage] != null;
    }

    previewVisible(): boolean {
        return !!this._definition.storage && this._filters[this._definition.storage] != null;
    }

    getPreviewName(): Observable<string> {
        return this._previewName$.asObservable();
    }

    clear(): boolean {
        if (this._definition.storage && this._filters[this._definition.storage] != null) {
            this._filters[this._definition.storage] = null;
            this.sliderState = LG_TRISTATE_SLIDER_STATE.UNSET;
            this.sliderLabel = "";
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<TristateSliderFilterRendererLineComponent> {
        return TristateSliderFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<TristateSliderFilterRendererLineComponent> {
        return TristateSliderFilterRendererLineComponent;
    }

    select(newValue: LG_TRISTATE_SLIDER_STATE): void {
        const typeName = this._getTypeName(newValue);
        this._filters[this._definition.storage!] = this._getFilterValue(newValue);
        const filterName = this._definition.name?.toLowerCase() ?? "";
        const previewName = typeName ? typeName + " " + filterName : "";
        this._previewName$.next(previewName);
    }

    private _getFilterValue(newValue: LG_TRISTATE_SLIDER_STATE): boolean | null {
        if (newValue === LG_TRISTATE_SLIDER_STATE.UNSET) return null;
        return newValue === LG_TRISTATE_SLIDER_STATE.CONTAINS;
    }

    private _getTypeName(newValue: LG_TRISTATE_SLIDER_STATE): string {
        if (this._definition.containsLabelShort && newValue === LG_TRISTATE_SLIDER_STATE.CONTAINS) {
            return this._definition.containsLabelShort;
        }
        if (this._definition.excludesLabelShort && newValue === LG_TRISTATE_SLIDER_STATE.EXCLUDES) {
            return this._definition.excludesLabelShort;
        }
        return this._lgTranslate.translate(
            `FW._Directives.TristateSliderFilterRenderer__${newValue}__short`
        );
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name ?? "",
            activeFn: () => this.active(),
            exportFn: () => {
                const value = this._filters[this._definition.storage!];
                return [value];
            }
        };
    }

    serialize(): string | null {
        if (!this.active()) return null;
        return this._filters[this._definition.storage!];
    }

    deserialize(state: string): boolean {
        const newState = !!state;
        const oldState = this._filters[this._definition.storage!];
        this._filters[this._definition.storage!] = newState;
        return newState !== oldState;
    }
}

export class TristateSliderFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "tristate-slider";

    create(
        definition: ITristateSliderFilterDefinition,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new TristateSliderFilterRenderer(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-tristate-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-tristate-slider-filter">
            <lg-tristate-slider
                #slider
                (stateChange)="_change($event)"
                [state]="_renderer.sliderState"
            ></lg-tristate-slider>
            <div
                class="lg-tristate-slider-filter__text"
                [class.lg-tristate-slider-filter__text--contains]="
                    _renderer.sliderState === LG_TRISTATE_SLIDER_STATE.CONTAINS
                "
                [class.lg-tristate-slider-filter__text--excludes]="
                    _renderer.sliderState === LG_TRISTATE_SLIDER_STATE.EXCLUDES
                "
            >
                <span
                    *ngIf="_renderer.sliderLabel"
                    class="lg-tristate-slider-filter__selected"
                    [class.lg-tristate-slider-filter__selected--contains]="
                        _renderer.sliderState === LG_TRISTATE_SLIDER_STATE.CONTAINS
                    "
                    [class.lg-tristate-slider-filter__selected--excludes]="
                        _renderer.sliderState === LG_TRISTATE_SLIDER_STATE.EXCLUDES
                    "
                    (click)="_setNextState()"
                    >{{ _renderer.sliderLabel }}</span
                >
                <span class="lg-tristate-slider-filter__label">{{ _definition.label }}</span>
            </div>
        </div>
    `
})
export class TristateSliderFilterRendererLineComponent extends FilterRendererComponentBase<
    ITristateSliderFilterDefinition,
    TristateSliderFilterRenderer
> {
    private _translateService = inject(LgTranslateService);

    @ViewChild("slider") slider!: LgTristateSliderComponent;
    LG_TRISTATE_SLIDER_STATE = LG_TRISTATE_SLIDER_STATE;
    private _isFinished = true;
    private _requireCallback = false;
    changeDelayed!: (state: LG_TRISTATE_SLIDER_STATE) => void;

    override _initialize(
        definition: ITristateSliderFilterDefinition,
        filters: any,
        isPopup: boolean,
        onChange: () => void,
        clear: () => void
    ): void {
        super._initialize(definition, filters, isPopup, onChange, clear);
        this._renderer.sliderState = this._getSliderState();
        this._renderer.sliderLabel = this._getSwitchedLabel(this._renderer.sliderState);
        this._requireCallback = !!(this._definition.delay && this._definition.delayCallback);
        this.changeDelayed = ldDebounce(
            (state: LG_TRISTATE_SLIDER_STATE) => {
                this._renderer.select(state);
                this._triggerChange();
                if (this._requireCallback && this._isFinished) {
                    this._isFinished = true;
                    this._definition.delayCallback?.call(this, this._isFinished);
                }
            },
            this._definition.delay,
            { trailing: true, leading: false }
        );
    }

    private _getSliderState(): LG_TRISTATE_SLIDER_STATE {
        if (this._definition.storage && this._filters[this._definition.storage] == null)
            return LG_TRISTATE_SLIDER_STATE.UNSET;
        if (this._definition.storage && this._filters[this._definition.storage])
            return LG_TRISTATE_SLIDER_STATE.CONTAINS;
        return LG_TRISTATE_SLIDER_STATE.EXCLUDES;
    }

    _change(state: LG_TRISTATE_SLIDER_STATE): void {
        this._renderer.sliderState = state;
        this._renderer.sliderLabel = this._getSwitchedLabel(state);
        if (this._requireCallback && !this._isFinished) {
            this._isFinished = false;
            this._definition.delayCallback?.call(this, this._isFinished);
        }
        this.changeDelayed(state);
    }

    _setNextState(): void {
        this.slider.setState();
    }

    private _getSwitchedLabel(state: LG_TRISTATE_SLIDER_STATE): string {
        if (state === LG_TRISTATE_SLIDER_STATE.UNSET) {
            return "";
        }
        if (state === LG_TRISTATE_SLIDER_STATE.CONTAINS) {
            return this._definition.containsLabel
                ? this._definition.containsLabel
                : this._translateService.translate(
                      "FW._Directives.TristateSliderFilterRenderer__contains"
                  );
        }
        return this._definition.excludesLabel
            ? this._definition.excludesLabel
            : this._translateService.translate(
                  "FW._Directives.TristateSliderFilterRenderer__excludes"
              );
    }
}
