import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { Subject, Observable } from "rxjs";
import { takeUntil, map } from "rxjs/operators";
import { LgObserveSizeService } from "@logex/framework/ui-core";

import { LgSlideoutService } from "./lg-slideout.service";
import { LgSlideoutApi, LgSlideoutVariant, LgSlideoutState } from "./lg-slideout.types";
import { LG_FW_UI_STATE_SERVICE } from "../lg-fw-ui-state";

@Component({
    selector: "lg-slideout",
    templateUrl: "./lg-slideout.component.html",
    host: {
        class: "lg-slideout"
    },
    encapsulation: ViewEncapsulation.None
})
export class LgSlideoutComponent implements OnInit, OnDestroy {
    private _element = inject(ElementRef);
    private _renderer = inject(Renderer2);
    private _service = inject(LgSlideoutService);
    private _sizeObserver = inject(LgObserveSizeService);
    private _uiState = inject(LG_FW_UI_STATE_SERVICE);

    /**
     * Slideout variant
     *
     * @type {"left" | "right"}
     */
    @Input() variant: LgSlideoutVariant = "right";
    @Input() width = 0;
    @Input() initializeAsPinned = false;

    /**
     * Slideout identifier
     */
    @Input({ required: true }) id!: string;

    /*
     * Can reduce boilerplate code so no TS logic is required for some scenarios:
     * <lg-slideout #myLgSlideout>...</lg-slideout>
     * <span *ngIf="myLgSlideout.pinned$ | async"></span>
     */
    pinned$!: Observable<boolean>;

    slideoutApi!: LgSlideoutApi;

    /**
     * Emits on pin toggle
     */
    @Output() readonly pinToggled = new EventEmitter<boolean>(false);

    /**
     * Emits on Slideout hide
     */
    @Output("hidden") readonly hidden = new EventEmitter<void>();

    _pinned = false;
    _hidePin = false;
    _expanded = false;
    _template?: TemplateRef<object> | undefined;
    _templateContext: object = {};

    private _destroyed$ = new Subject<void>();

    async ngOnInit(): Promise<void> {
        this.slideoutApi = this._service.api(this.id, this.variant);

        this.slideoutApi.state$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                ({ pinned, expanded, template, templateContext, hidePin }: LgSlideoutState) => {
                    let needToSetElementWidth = false;

                    if (
                        (template != null && template !== this._template && pinned) ||
                        (this._pinned !== pinned && this._pinned != null)
                    ) {
                        this._uiState.setSidebarPinnedPanelId(
                            this.variant,
                            this._service.activePanelVariant ?? ""
                        );
                    }

                    if (this._pinned !== pinned) {
                        if (this._pinned != null) {
                            this._uiState.setSidebarPinned(this.variant, pinned);
                            this._sizeObserver.recalculate();
                        }
                        this._pinned = pinned;
                        this.pinToggled.emit(pinned);
                        needToSetElementWidth = true;
                    }
                    if (this._expanded !== expanded) {
                        this._expanded = expanded;
                        needToSetElementWidth = true;
                    }

                    this._hidePin = hidePin ?? false;
                    this._template = template;
                    this._templateContext = templateContext ?? {};

                    if (needToSetElementWidth) this._trySetElementWidth();
                }
            );

        this.pinned$ = this.slideoutApi.state$.pipe(map(state => state.pinned));

        let initAsPinned = !!this.initializeAsPinned;

        const uiStatePinned = await this._uiState.getSidebarPinned(this.variant);
        if (uiStatePinned != null) {
            initAsPinned = uiStatePinned;
        }
        let availablePanels: string[] = [];
        this._pinned = this._pinned || initAsPinned;
        this.slideoutApi.togglePinned(this._pinned);
        const templatePanelId = await this._uiState.getSidebarPinnedPanelId(this.variant);
        this._service.availablePanelsState$.pipe(takeUntil(this._destroyed$)).subscribe(panels => {
            if (Object.keys(panels).length === 0) return;
            if (initAsPinned) {
                this.slideoutApi.toggleExpanded(true);
                this._trySetElementWidth();
                initAsPinned = false;
            }

            const panel =
                this._service.getAvailablePanel(templatePanelId) ??
                this._service.getAvailablePanel(Object.keys(panels)[0]);
            if (panel != null && Object.keys(panels).length > availablePanels.length) {
                availablePanels = Object.keys(panels);
                this.slideoutApi.setTemplate(panel.panelTemplate);
                this._service.setActivePanel(panel.panelVariant);
            }
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _hide(): void {
        this.slideoutApi.toggleExpanded(false);
    }

    _togglePin(): void {
        this.slideoutApi.togglePinned();
        this._uiState.setSidebarPinned(this.variant, this._pinned);
        this._sizeObserver.recalculate();
    }

    private _trySetElementWidth(): void {
        if (this._element) {
            const newWidth = this._pinned && this._expanded ? this.width : 0;
            this._renderer.setStyle(this._element.nativeElement, "min-width", `${newWidth}px`);
        }
    }
}
