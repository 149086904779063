<div class="body__holder">
    <lg-sidebar>
        <lg-sidebar-switch
            *lgSidebarPanel="
                let sidebar;
                imageClass: 'logo-scm__bg--transparent lg-sidebar-btn__image--no-text';
                large: true;
                top: true;
                nameAlwaysVisible: true;
                disabled: true;
                id: 'logo'
            "
            [sidebar]="sidebar"
        ></lg-sidebar-switch>

        <lg-sidebar-menu
            *lgSidebarPanel="
                let sidebar;
                nameLc: 'APP._Sidebar.Menu';
                icon: 'icon-menu';
                top: true;
                nameAlwaysVisible: true;
                nameClass: 'lg-sidebar-btn__name--menu';
                id: 'menu'
            "
            [sidebar]="sidebar"
        ></lg-sidebar-menu>

        <lg-sidebar-help
            *lgSidebarPanel="
                let sidebar;
                nameLc: 'APP._Sidebar.Help';
                icon: 'icon-sidebar-help';
                top: false;
                id: 'help'
            "
            [versionClickable]="false"
            [standardLinks]="_standardLinks"
        >
            <ng-template #extension>
                <div class="lg-sidebar-help__bottom">
                    <div class="lg-sidebar-help__bottom-group">
                        <div class="lg-sidebar-help__bottom-group__header">
                            {{ "APP._Help.User" | lgTranslate }}
                        </div>

                        <div class="lg-sidebar-help__bottom-group__text">{{ _name }}</div>
                    </div>

                    <div class="lg-sidebar-help__bottom-group">
                        <div class="lg-sidebar-help__bottom-group__header">
                            {{ "APP._Help.Time_is" | lgTranslate }}
                        </div>

                        <div class="lg-sidebar-help__bottom-group__text">
                            {{ _today | date: "dd-MM-yyyy HH:mm" }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </lg-sidebar-help>

        <ng-container *ngIf="_viewSettings">
            <lg-sidebar-settings
                *lgSidebarPanel="
                    let sidebar;
                    nameLc: 'APP._Sidebar.Settings';
                    icon: 'icon-settings';
                    top: false;
                    id: 'settings'
                "
                [sidebar]="sidebar"
            ></lg-sidebar-settings>
        </ng-container>

        <div *ngIf="_devMode">
            <lg-sidebar-dev-widget *lgSidebarWidget></lg-sidebar-dev-widget>
        </div>
    </lg-sidebar>

    <div class="body__content">
        <div class="breadcrumb-row">
            <div class="breadcrumb-row__split">
                <div class="breadcrumb-row__split__subrow-one">
                    <lg-breadcrumb titlePrefixLc="APP.ToolName"></lg-breadcrumb>

                    <lg-sibling-placeholder
                        name="breadcrumb-row.left.second"
                    ></lg-sibling-placeholder>
                </div>
            </div>

            <lg-sibling-placeholder name="breadcrumb-row.right"></lg-sibling-placeholder>
        </div>

        <router-outlet></router-outlet>
    </div>

    <lg-slideout [width]="360" variant="right" id="filters-right-slideout">
        <lg-filters-panel [disableBookmarks]="true"></lg-filters-panel>
    </lg-slideout>
</div>
