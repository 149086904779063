import {
    Component,
    Input,
    ContentChildren,
    AfterContentInit,
    QueryList,
    OnDestroy,
    OnChanges
} from "@angular/core";
import { Observable, from, ReplaySubject } from "rxjs";
import { mergeMap, switchMap, startWith, mergeWith, map } from "rxjs/operators";

import { LgColDefinitionRowSource } from "../services";
import { LgColBase } from "./lg-col.component";

@Component({
    selector: "lg-col-row",
    template: ""
})
export class LgColRowComponent implements AfterContentInit, OnDestroy, OnChanges {
    public _change$: Observable<LgColRowComponent> | null = null;

    @Input({ required: true }) public id!: string;

    @Input() public set columnClass(val: string | undefined) {
        this._columnClasses = undefined;
        if (val === undefined) return;

        val = val.trim();
        if (!val) return;

        this._columnClasses = val
            .split(" ")
            .map(c => c.trim())
            .filter(c => !!c);
    }

    get columnClass(): string | undefined {
        if (this._columnClasses === undefined) return undefined;
        return this._columnClasses.join(" ");
    }

    // ---------------------------------------------------------------------------------------------
    private _columnClasses: string[] | undefined = undefined;
    private readonly _localChange$ = new ReplaySubject<LgColRowComponent>(1);

    @ContentChildren(LgColBase) _columns!: QueryList<LgColBase>;

    // ---------------------------------------------------------------------------------------------
    public _getSourceDefinition(): LgColDefinitionRowSource {
        return {
            id: this.id,
            columnClasses: this._columnClasses,
            columns: this._columns.toArray().map(c => c._getSourceDefinition())
        };
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnChanges(): void {
        this._localChange$.next(this);
    }

    // ---------------------------------------------------------------------------------------------
    public ngAfterContentInit(): void {
        this._change$ = this._columns.changes.pipe(
            startWith(null),
            switchMap(() =>
                from(this._columns.toArray()).pipe(
                    mergeMap(col => col._change$),
                    map(() => this)
                )
            ),
            mergeWith(this._localChange$.asObservable())
        );
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        this._localChange$.complete();
    }
}
