import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RoleGuardService } from "@logex/framework/lg-application";
import { AuthorizedComponent } from "./authorized.component";
import { LoginComponent } from "./login.component";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "editor"
    },
    {
        path: "login",
        component: LoginComponent
    },
    {
        path: "",
        pathMatch: "prefix",
        component: AuthorizedComponent,
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: {
            navigationId: "root"
        },
        children: [
            {
                path: "editor",
                loadChildren: () => import("./editor/editor.module").then(m => m.EditorModule),
                data: {
                    navigationId: "editor"
                }
            }
        ]
    },
    {
        path: "**",
        redirectTo: "editor"
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
