import { Dictionary } from "lodash";
import { Inject, LOCALE_ID, Injectable } from "@angular/core";
import { LgLocalizationSettings } from "@logex/framework/lg-localization";

@Injectable()
export class AppLocalizationSettings implements LgLocalizationSettings {
    readonly bootstrapDone = Promise.resolve();

    readonly languages: _.Dictionary<any[]> = {};

    private _preferredLanguage;

    constructor(@Inject(LOCALE_ID) private _locale: string) {
        const preferredLanguage = localStorage.getItem("preferredLanguage");
        if (preferredLanguage != null && this.availableLanguages.includes(preferredLanguage)) {
            this._preferredLanguage = preferredLanguage;
        } else {
            this._preferredLanguage = this._locale;
        }
    }

    get fallbackLanguage(): string {
        return "en";
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return ["en-GB", "nl-NL", "en-150"];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    switchCurrentLanguage(lang: string): void {
        localStorage.setItem("preferredLanguage", lang);
        window.location.reload();
    }

    addStrings(lang: string, strings: Dictionary<any>): void {
        this.languages[lang] = [...(this.languages[lang] ?? []), strings];
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;
    }
}
