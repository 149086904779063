import ldSortBy from "lodash-es/sortBy";

/**
 * Most of *lgConsole* operations belong to different log levels.
 */
export enum ConsoleLogLevel {
    All = 0,
    Perf = 1,
    Debug = 2,
    Info = 3,
    Warn = 4,
    Error = 5,
    None = 6
}

// ----------------------------------------------------------------------------------
//
interface IConsoleConfigurationLoggerInfo {
    namespace: string[];
    level: ConsoleLogLevel;
}

/**
 * Component *lgConsoleConfiguration* in module *logex.services*. Implemented as Angular constant, so it is available at
 * application configuration.
 *
 * Holds shared configuration for *lgConsole* instances. See [[Console]] documentation on how to configure the log levels.
 */
export class LgConsoleConfiguration {
    // ----------------------------------------------------------------------------------
    // Fields

    /**
     * Possible levels: all, perf, debug, info, warn, error, none. Default value is "All".
     */
    rootLogLevel: ConsoleLogLevel = ConsoleLogLevel.All;
    outputTrace = false;

    private _loggers: IConsoleConfigurationLoggerInfo[] = [];

    // ----------------------------------------------------------------------------------
    //

    /**
     *
     */
    constructor() {
        this.reset();
    }

    /**
     * Resets the configuration to the default state.
     */
    reset(): this {
        this.rootLogLevel = ConsoleLogLevel.All;
        this.outputTrace = false;
        this._loggers = [];
        this.logger("Logex", ConsoleLogLevel.Warn);
        return this;
    }

    /**
     * Configures root logger for specified namespace.
     *
     * @param level The global (root) logging level.
     */
    rootLogger(level: ConsoleLogLevel): this {
        this.rootLogLevel = level;
        return this;
    }

    /**
     * Configures logger for specified namespace.
     *
     * @param namespace
     * @param level
     */
    logger(namespace: string, level: ConsoleLogLevel): this {
        const path = namespace.split(".");
        this._loggers.push({
            namespace: path,
            level
        });
        return this;
    }

    /**
     * Show log, warn, debug, etc methods also output stack trace.
     *
     * @param trace
     */
    trace(trace: boolean): this {
        this.outputTrace = trace;
        return this;
    }

    /**
     * Gets configured log level for given source
     *
     * @param source Name or namespace of a component. Null refers to the root logging level.
     */
    getLogLevel(source: string): ConsoleLogLevel {
        if (!source) return this.rootLogLevel;

        const path = source.split(".");
        let infos = this._loggers.filter(loggerInfo => {
            const loggerNs = loggerInfo.namespace;
            for (let i = 0; i < path.length; i++) {
                if (i >= loggerNs.length) {
                    // Requested source is longer than logger's one -> Suits.
                    return true;
                }

                if (path[i] !== loggerNs[i]) return false;
            }
            return path.length >= loggerNs.length;
        });

        if (infos.length === 0) return this.rootLogLevel;

        infos = ldSortBy(infos, x => x.namespace.length);
        return infos[infos.length - 1].level;
    }
}
