import ldFirst from "lodash-es/first";
import ldFind from "lodash-es/find";
import { Component, ViewEncapsulation } from "@angular/core";
import { first, map } from "rxjs/operators";

import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";
import { IComboFilterDefinition, ComboFilterRendererBase } from "./combo-filter-renderer";
import { IComboFilter2Definition } from "./combo-filter2-renderer";
import { ComponentType } from "@angular/cdk/portal";

// ----------------------------------------------------------------------------------
//
export interface ISelectedItemFilterDefinition extends IFilterDefinition {
    filterType: "selected";

    /**
     * Name of the parent combo filter. Will be used to show description of the currently selected object and to show a combo filter dropdown.
     */
    parentFilter: string;
}

// ----------------------------------------------------------------------------------
/**
 * Filter representing one item that is selected out of all the choices from given parent combo filter.
 * Used to show a current item in a filter preview when you drill down into the list of specialisms, for example.
 */
export class SelectedItemFilterRenderer<T> implements IFilterRenderer {
    // ----------------------------------------------------------------------------------
    // Fields
    parentFilter: IComboFilterDefinition<T> | IComboFilter2Definition<T>;
    parentRenderer: ComboFilterRendererBase;
    filterData: Record<string, string> | null = null;

    // ----------------------------------------------------------------------------------
    //
    constructor(
        private _definition: ISelectedItemFilterDefinition,
        definitions: IFilterDefinition[],
        private _filters: any,
        private _filterSet: LgFilterSet
    ) {
        this.parentFilter = definitions.find(d => d.id === this._definition.parentFilter) as any;
        if (!this.parentFilter) {
            throw new Error(`Parent filter "${this._definition.parentFilter}" is not found.`);
        }

        const parentFilterType = this.parentFilter.filterType;
        if (parentFilterType !== "combo" && parentFilterType !== "combo2") {
            throw new Error(
                `Filter type "selected" could be attached only to a parent filter of type "combo" or "combo2"`
            );
        }

        this.parentRenderer = this.parentFilter.renderer as any;

        this._definition.visible = () => false;
        this._definition.name = this.parentFilter.name ?? "";
    }

    createStorage(): void {
        if (this._filters[this._definition.storage!] == null) {
            this._filters[this._definition.storage!] = undefined;
        }
    }

    active(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    previewVisible(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    clear(): boolean {
        if (this._filters[this._definition.storage!] != null) {
            this._filters[this._definition.storage!] = undefined;
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        // Note: the filter is never visible (see constructor) thus this is safe
        return {} as ComponentType<FilterRendererComponentBase<any, any>>;
    }

    getExportDefinition(): IFilterExportDefinition | null {
        return null;
    }

    getPopupComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        const getFilterData = <T2 extends number | string>(
            filterDef: IComboFilterDefinition<T2> | IComboFilter2Definition<T2>,
            id: T2
        ): Record<string, string> | null => {
            switch (filterDef.filterType) {
                case "combo": {
                    const source = filterDef.source();
                    if ("subscribe" in source) {
                        source
                            .pipe(
                                first(),
                                map(options => {
                                    const res: Record<string, string> = {};
                                    const option = ldFind(options, e => e.id === id);
                                    if (option) res[option.id] = option.name;
                                    if (this.filterData === null) this.filterData = res;
                                })
                            )
                            .subscribe();
                        return null;
                    } else {
                        const res: Record<string, string> = {};
                        const option = source.find(e => e.id === id);
                        if (option) res[option.id] = option.name;
                        return res;
                    }
                }

                default:
                case "combo2": {
                    const res: Record<string, string> = {};
                    const option = ldFirst(filterDef.mapToOptions([id]));
                    if (option) res[option.id] = option.name;
                    return res;
                }
            }
        };

        this.filterData = getFilterData(
            this.parentFilter,
            this._filters[this._definition.storage!]
        );

        return SelectedFilterRendererPopupComponent;
    }

    _onChanged(newFilter: Record<string, string>): void {
        this.filterData = newFilter;

        if (this.filterData.$empty) {
            this.clear();
        } else {
            const keys = Object.keys(this.filterData);
            if (keys.length === 1) {
                // Only one option is selected - change the selected item
                const id = keys[0];
                this._filters[this._definition.storage!] = id;
                const parentFilterData = this._filters[this.parentFilter.storage!];
                if (!parentFilterData.$empty && !parentFilterData[id]) {
                    // Parent filter is not empty and doesn't have the selected option. Add it.
                    parentFilterData[id] = this.filterData[id];
                }
            } else {
                // Multiple options are selected - change the parent filter
                this._filters[this.parentFilter.storage!] = this.filterData;
                this.clear();
                this._filterSet.updateVisible();
            }
        }
    }

    serialize(): string | null {
        if (!this.active()) return null;
        return null;
        // note: would we want to serialize this filter?
        // return JSON.stringify(this.filters[this.definition.storage]);
    }

    deserialize(_state: string): boolean {
        return false;
        /*
        Would we want to serialize this filter?
        var newState = JSON.parse(state);
        if (!_.isEqual(this.filters[this.definition.storage], newState)) {
            this.filters[this.definition.storage] = newState;
            return true;
        }
        return false;
        */
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class SelectedItemFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "selected";

    create(
        definition: ISelectedItemFilterDefinition,
        filters: Record<string, any>,
        definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new SelectedItemFilterRenderer(definition, definitions, filters, filterSet);
    }
}

// Popup template  ---------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-selected-filter-renderer-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="header">
            {{ "FW._Directives.ComboFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.ComboFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-multi-filter
            placeholder="{{ _renderer.parentFilter.placeholder }}"
            [source]="_renderer.parentRenderer.source()"
            [filter]="_renderer.filterData"
            (filterChange)="_onChanged($event)"
        ></lg-multi-filter>
    `,
    encapsulation: ViewEncapsulation.None
})
export class SelectedFilterRendererPopupComponent extends FilterRendererComponentBase<
    ISelectedItemFilterDefinition,
    SelectedItemFilterRenderer<any>
> {
    _onChanged(newFilter: Record<string, string>): void {
        this._renderer._onChanged(newFilter);
        this._triggerChange();
    }
}
