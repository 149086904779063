import { Component, OnDestroy, HostListener, inject } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import {
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_USER_INFO,
    VersionService,
    IVersion
} from "@logex/framework/lg-application";
import { Nullable } from "@logex/framework/types";

@Component({
    selector: "lg-sidebar-dev-widget",
    templateUrl: "./lg-sidebar-dev-widget.component.html",
    host: {
        class: "lg-sidebar-widget lg-sidebar-dev-widget",
        "[class.lg-sidebar-dev-widget--faded]": "_faded",
        "[hidden]": "!_hasAccess"
    }
})
export class LgSidebarDevWidgetComponent implements OnDestroy {
    appConfiguration = inject(LG_APP_CONFIGURATION);
    appInfo = inject(LG_APP_INFO);
    userInfo = inject(LG_USER_INFO);
    private _versionService = inject(VersionService<IVersion>);

    _versionId: Nullable<number>;
    _hasAccess = false;
    _faded = false;
    private _destroyed$ = new Subject<void>();

    _testMachine = "";

    constructor() {
        this._hasAccess = this.userInfo.hasPermission("internal");

        setTimeout(() => {
            this._faded = true;
        }, 5000);

        this._versionService.version.pipe(takeUntil(this._destroyed$)).subscribe(version => {
            this._versionId = version.id;
        });

        const testMachine = this.appConfiguration.testMachine ?? "";

        if (testMachine.toLowerCase().search("exec") > -1) this._testMachine = "EXEC";
        if (testMachine.toLowerCase().search("test") > -1) this._testMachine = "TEST";
        if (testMachine.toLowerCase().search("dev") > -1) this._testMachine = "DEV";
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    @HostListener("dblclick")
    _onDblClick(): void {
        this._hasAccess = false;
    }
}
