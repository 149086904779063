import { Component } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";
import { IDropdownDefinition } from "@logex/framework/ui-core";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";

// Tri-state renderer definition extensions ------------------------------------------------------------------------
export interface ITristateFilterDefinition extends IFilterDefinition {
    filterType: "tristate";
    /**
     * Name of the "filter off" option. Defaults to "Geen"
     */
    emptyOption?: string;

    /**
     * Name of the TRUE option. Defaults to "Ja"
     */
    checkedOption?: string;

    /**
     * Name of the FALSE option. Defaults to "Nee"
     */
    uncheckedOption?: string;

    /**
     * Translation id prefix. The 3 options will be translated by appending these postfixes:
     * "__checked"
     * "__unchecked"
     * "__empty"
     * Note that the individual names (emptyOption etc) have precedence, if specified.
     */
    prefixLC?: string;
}
// Checkbox renderer -----------------------------------------------------------------------------------------------
/**
 * Renderer for simple tri-state boolean filter (look for true, look for false, do not filter). This is currently
 * rendered as dropdown with 3 options. See ITristateFilterDefinition for list of supported options.
 * The "off" state is indicated by null value of the filter.
 *
 * Note: for technical reasons, the "off" value can be temporarily stored as "null". The onChange event won't be
 * triggered before this is normalized, so this is issue only if you're watching on the filter value (which you shouldn't)
 */
export class TristateFilterRenderer implements IFilterRenderer {
    dropdownDefinition: IDropdownDefinition<number>;

    constructor(
        private _definition: ITristateFilterDefinition,
        private _filters: any,
        lgTranslate: LgTranslateService
    ) {
        const prefix = this._definition.prefixLC || "FW._Directives.TristateFilterRenderer";
        if (!this._definition.emptyOption) {
            this._definition.emptyOption = lgTranslate.translate(prefix + "__empty");
        }
        if (!this._definition.checkedOption) {
            this._definition.checkedOption = lgTranslate.translate(prefix + "__checked");
        }
        if (!this._definition.uncheckedOption) {
            this._definition.uncheckedOption = lgTranslate.translate(prefix + "__unchecked");
        }

        this.dropdownDefinition = {
            groups: [
                {
                    entries: [
                        { id: null, name: this._definition.emptyOption },
                        { id: 1, name: this._definition.checkedOption },
                        { id: 0, name: this._definition.uncheckedOption }
                    ]
                }
            ]
        };
    }

    createStorage(): void {
        if (this._filters[this._definition.storage!] === undefined) {
            this._filters[this._definition.storage!] = null;
        }
    }

    active(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    previewVisible(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    clear(): boolean {
        if (this._filters[this._definition.storage!] != null) {
            this._filters[this._definition.storage!] = null;
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return TristateFilterRendererLineComponent;
    }

    select(newValue: any): void {
        if (newValue === "null") {
            newValue = null;
        }
        this._filters[this._definition.storage!] = newValue;
    }

    getPopupComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return TristateFilterRendererPopupComponent;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name ?? "",
            activeFn: () => this.active(),
            exportFn: () => {
                const value = this._filters[this._definition.storage!];
                return [value ? this._definition.checkedOption : this._definition.uncheckedOption];
            }
        };
    }

    serialize(): string | null {
        if (!this.active()) return null;
        return this._filters[this._definition.storage!] ? "1" : "0";
    }

    deserialize(state: string): boolean {
        const newState = !!state;
        const oldState = this._filters[this._definition.storage!];
        this._filters[this._definition.storage!] = newState;
        return newState !== oldState;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class TristateFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "tristate";

    create(
        definition: ITristateFilterDefinition,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new TristateFilterRenderer(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-tristate-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <label>{{ _definition.label }}:</label>
        <div class="control like-combo-filter">
            <lg-dropdown
                [current]="_filters[_definition.storage ?? '']"
                [definition]="_renderer.dropdownDefinition"
                [condensed]="true"
                [hideSearch]="true"
                [emptyText]="_definition.emptyOption"
                (currentChange)="_change($event)"
            ></lg-dropdown>
        </div>
    `
})
export class TristateFilterRendererLineComponent extends FilterRendererComponentBase<
    ITristateFilterDefinition,
    TristateFilterRenderer
> {
    _change(value: number | string): void {
        this._renderer.select(+value);
        this._triggerChange();
    }
}

// Popup template --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-tristate-filter-renderer-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="header">
            {{ "FW._Directives.TristateFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.TristateFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-dropdown
            [current]="_filters[_definition.storage ?? '']"
            [definition]="_renderer.dropdownDefinition"
            [condensed]="true"
            [hideSearch]="true"
            [emptyText]="_definition.emptyOption"
            (currentChange)="_change($event)"
        ></lg-dropdown>
    `
})
export class TristateFilterRendererPopupComponent extends FilterRendererComponentBase<
    ITristateFilterDefinition,
    TristateFilterRenderer
> {
    _change(value: number | string): void {
        this._renderer.select(+value);
        this._triggerChange();
    }
}
