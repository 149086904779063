import { Component, Input, Output, EventEmitter } from "@angular/core";

import type { LgFilterSet, IVisibleDefinitionGroup } from "../lg-filterset";
import type { IFilterDefinition } from "../filter-definition";

@Component({
    selector: "lg-filterset-preview",
    template: `
        <ng-container *ngFor="let entry of list.potentiallyVisiblePreviewDefinitions">
            <lg-filterset-preview-item
                [definition]="entry"
                *ngIf="entry.renderer?.previewVisible()"
                [list]="list"
                (filterChange)="_filterChanged(entry)"
            ></lg-filterset-preview-item>
        </ng-container>
    `,
    host: {
        class: "lg-filterset-preview"
    }
})
export class LgFiltersetPreviewComponent {
    /**
     * Definition of all filters (required).
     */
    @Input({ alias: "definition", required: true }) list!: LgFilterSet;

    @Output() readonly filterChange = new EventEmitter<void>();

    _filterChanged(entry: IFilterDefinition): void {
        this.list.triggerOnChanged(entry);
        this.filterChange.next();
    }

    _groupIdentity(_index: number, entry: IVisibleDefinitionGroup): number {
        return entry.index;
    }

    _entryIdentity(_index: number, entry: IFilterDefinition): string {
        return entry.id;
    }
}
