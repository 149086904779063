import { DbFilterSetStateResponse } from "../lg-filterset-state-gateway.types";

import { FilterSetState } from "../lg-filterset.types";

export function mapFiltersetStateFromDb(response: DbFilterSetStateResponse): FilterSetState[] {
    if (!response || !response.states || !response.states.length) {
        return [];
    }

    const states: FilterSetState[] = [];
    const lookup: Record<string, FilterSetState> = {};
    for (const filterSet of response.states) {
        const state = {
            filterHostId: filterSet.filter_host_id,
            stateId: filterSet.state_id,
            name: filterSet.name,
            overwrite: filterSet.overwrite,
            shared: filterSet.shared,
            isOwn: filterSet.is_own,
            authorName: filterSet.author_name,
            dateInsert: filterSet.date_insert
                ? new Date(Date.parse(filterSet.date_insert))
                : undefined,
            parts: {}
        } satisfies FilterSetState;

        states.push(state);
        lookup[filterSet.state_id] = state;
    }

    for (const part of response.parts) {
        lookup[part.state_id].parts[part.filter_id] = part.storage;
    }

    return states;
}
