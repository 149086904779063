import { ComponentRef, Injectable, Injector, OnDestroy, inject } from "@angular/core";
import { ISnackbarOptions } from "./lg-snackbar.types";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { LgSnackbarComponent, LG_SNACKBAR_OPTIONS } from "./lg-snackbar.component";
import { atNextFrame } from "@logex/framework/utilities";
import { of, Subject } from "rxjs";
import { concatMap, debounceTime, delay, takeUntil, tap } from "rxjs/operators";

const DEFAULT_DELAY_HIDE = 3000;
const DEFAULT_WIDTH = 256;
const SEPARATION_DELAY = 500;
const TOP_OFFSET = 16;

@Injectable({ providedIn: "root" })
export class LgSnackbarService implements OnDestroy {
    private _overlay = inject(Overlay);
    private _parentInjector = inject(Injector);

    constructor() {
        // Make delay before showing next snackbar
        let forceDelay = false;
        this._snackbarOptions$
            .pipe(
                concatMap(item => {
                    return forceDelay ? of(item).pipe(delay(SEPARATION_DELAY)) : of(item);
                }),
                tap(options => {
                    this._showAfterInterval(options);
                    forceDelay = true;
                }),
                debounceTime(SEPARATION_DELAY + 100),
                tap(() => {
                    forceDelay = false;
                }),
                takeUntil(this._destroyed$)
            )
            .subscribe();
    }

    private _snackbarOverlays: Array<{
        overlayRef: OverlayRef;
        componentRef: ComponentRef<LgSnackbarComponent>;
    }> = [];

    private _snackbarOptions$ = new Subject<ISnackbarOptions>();
    private _destroyed$: Subject<void> = new Subject();

    show(options: ISnackbarOptions): void {
        this._snackbarOptions$.next(options);
    }

    private _showAfterInterval(options: ISnackbarOptions): void {
        this._removeDetachedSnackbars();

        const overlayRef = this._overlay.create({
            positionStrategy: this._overlay.position().global().left().bottom(),
            width: options.width ?? DEFAULT_WIDTH
        });

        const injector = Injector.create({
            parent: this._parentInjector,
            providers: [
                { provide: LG_SNACKBAR_OPTIONS, useValue: options },
                { provide: OverlayRef, useValue: overlayRef }
            ]
        });
        const portal = new ComponentPortal(LgSnackbarComponent, null, injector);
        const componentRef = overlayRef.attach(portal);

        if (options.autoHide !== false) {
            this._hide(componentRef.instance, options.delayHide ?? DEFAULT_DELAY_HIDE);
        }

        atNextFrame(() => {
            this._moveOldSnackbarsUp(overlayRef.overlayElement.getBoundingClientRect().height);
            this._snackbarOverlays.push({ overlayRef, componentRef });
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    private _hide(component: LgSnackbarComponent, delay: number): void {
        setTimeout(() => component.hide(), delay);
    }

    private _moveOldSnackbarsUp(bottomOffset: number): void {
        this._snackbarOverlays.forEach(overlay => {
            const lastPosition = overlay.overlayRef.overlayElement.getBoundingClientRect();
            const bottomPosition = window.innerHeight - lastPosition.bottom;

            if (lastPosition.top - lastPosition.height > 0) {
                overlay.overlayRef.updatePositionStrategy(
                    this._overlay
                        .position()
                        .global()
                        .left()
                        .bottom(`${bottomPosition + bottomOffset}px`)
                );
                overlay.componentRef.instance.moveUp(bottomOffset);
            } else {
                overlay.overlayRef.updatePositionStrategy(
                    this._overlay.position().global().left().top(`${TOP_OFFSET}px`)
                );
            }
        });
    }

    _removeDetachedSnackbars(): void {
        this._snackbarOverlays = this._snackbarOverlays.filter(overlay =>
            overlay.overlayRef.hasAttached()
        );
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
