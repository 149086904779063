import {catchError, Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {RegisterStrongContractRequest} from "../../http-client/models/register-contract/RegisterStrongContractRequest";
import {GetContractEditorResponse} from "../../http-client/models/get-contract-by-id/GetContractEditorResponse";
import {ContractEditorApiErrorResponse} from "../../http-client/models/ContractEditorApiErrorResponse";
import {ServerGatewayBase} from "@logex/framework/lg-application";
import {UpdateStrongContractRequest} from "../../http-client/models/update-contract/UpdateStrongContractRequest";

@Injectable()
export class ScmEditorApiService extends ServerGatewayBase {
    setScmEditorApiUrl(scmEditorApiUrl: string) {
        this._setBaseUrl(scmEditorApiUrl);
    }

    registerContract(data: RegisterStrongContractRequest): Observable<any | ContractEditorApiErrorResponse> {
        return this._postQuery(
            this._buildApiUrl('strong-contract/register-contract'),
            { body: data }
        ).pipe(
            catchError(error => {
                return of({
                    error: error,
                    message: error.error
                });
            }),
        );
    }

    updateContract(data: UpdateStrongContractRequest): Observable<any | ContractEditorApiErrorResponse> {
        return this._put(
            this._buildApiUrl('strong-contract/update-contract'),
            { body: data }
        ).pipe(
            catchError(error => {
                return of({
                    error: error,
                    message: error.error
                });
            }),
        );
    }

    getContractById(
        uuid: string,
        addTree = false
    ): Observable<GetContractEditorResponse | any> {
        return this._get(
            this._buildApiUrl(`strong-contract/${uuid}?addTree=${addTree}`),
        );
    }

    /**
     * @param {string} endpointUrl relative URL to endpoint
     * @return string
     * @private
     */
    private _buildApiUrl(endpointUrl: string) {
        return `${this._baseUrl}/api/editor/${endpointUrl}`;
    }
}
