import { InjectionToken } from "@angular/core";

export const LG_USER_INFO = new InjectionToken<IUser>("lgUserInfo");

// ----------------------------------------------------------------------------------
//
export interface IUser {
    /**
     * @deprecated
     * Use userid instead
     */
    id?: number | undefined;
    name: string;
    userid?: string | undefined;
    email?: string;
    login?: string;
    impersonator?: string;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;
    roles: Record<string, boolean>;
    title: string;
    hasPermission(permission: string): boolean;
}

export class AppUser implements IUser {
    /**
     * @deprecated
     * Use userid instead
     */
    id?: number | undefined;
    name!: string;
    roles: Record<string, boolean> = {};
    title = "";
    userid?: string;
    ziekenhuiscode!: number;
    ziekenhuisnaam!: string;

    public hasPermission(role: string): boolean {
        return this.roles[role] ?? false;
    }
}
