import { Component, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { LgIconComponent } from "./lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-selection-checkbox",
    template: `<lg-icon icon="icon-check-thick"></lg-icon>`,
    imports: [LgIconComponent],
    host: {
        class: "lg-selection-checkbox",
        "[class.lg-selection-checkbox--checked]": "state===1",
        "[class.lg-selection-checkbox--indeterminate]": "state===2",
        "[class.lg-selection-checkbox--disabled]": "disabled"
    }
})
export class LgSelectionCheckboxComponent {
    /**
     * Selection state.
     *
     * @param value
     * `0` or `false` - unchecked, `1` or `true` - checked, `2` - intermediate
     */
    @Input({ required: true }) set state(value: boolean | number) {
        // eslint-disable-next-line eqeqeq
        if (value == +value) {
            const num = +value;
            switch (num) {
                case 0:
                case 1:
                case 2:
                    this._state = num;
                    break;
                default:
                    this._state = 2;
                    break;
            }
        } else {
            const bool = toBoolean(value);
            this._state = bool ? 1 : 0;
        }
    }

    get state(): boolean | number {
        return this._state;
    }

    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    /**
     * Emits checked changes on click.
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output("checked") readonly checked = new EventEmitter<boolean>();

    @HostListener("click", ["$event"])
    _clicked($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        if (this._disabled) return;

        if (this.state === 0) {
            this.checked.next(true);
        } else {
            this.checked.next(false);
        }
    }

    _state!: 0 | 1 | 2;
    private _disabled = false;
}
